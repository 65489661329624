import { createContext, ReactNode, useEffect, useState } from 'react';

type WindowValuesType = number | undefined;

export interface ViewportInfo {
  width: WindowValuesType;
  height: WindowValuesType;
  handleResize: () => void;
}

export const ViewportContext = createContext<ViewportInfo>({
  width: undefined,
  height: undefined,
  handleResize: () => undefined,
});

interface ViewportProviderProps {
  children: ReactNode;
}

const ViewportProvider = ({ children }: ViewportProviderProps) => {
  const [width, setWidth] = useState<WindowValuesType>(undefined);
  const [height, setHeight] = useState<WindowValuesType>(undefined);

  const handleResize = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height, handleResize }}>
      {children}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
